import { Axios } from "@/utils/axios";

export type Contact = {
  name: string;
  phone: string;
};

/** 유저의 모든 역락쳐 id 순 */
export type ObituaryContactResponse = {
  /** 연락처 고유한 키 값(삭제 시 사용) */
  key: string;
} & Contact;

export const addObituaryContacts = (body: Contact[]) => {
  return Axios.post("/obituary_contacts/", body);
};

/** response: post 응답과 동일. 요청자의 모든 연락처 목록 id 순으로 */
export const getObituaryContacts = async () => {
  const { data } = await Axios.get<ObituaryContactResponse[]>(
    "/obituary_contacts/"
  );
  return data;
};

/**
DELETE /obituary_contacts/?keys=키1,키2,...
response: 204 no content
request user 것으로 등록된 연락처만 삭제함
*/
export const deleteObituaryContacts = async (keys: string) => {
  return await Axios.delete("/obituary_contacts/", {
    params: {
      keys,
    },
  });
};

export type ShareObituaryToContactsBody = {
  contact_keys: string[];
  sangju: string;
};

export const shareObituaryToContacts = (
  obituaryKey: string,
  body: ShareObituaryToContactsBody
) => {
  return Axios.post(`/obituaries/${obituaryKey}/contact_shares/`, body);
};
