import { PropsWithChildren, useEffect } from "react";
import { atom, useAtomValue } from "jotai";
import { getCookie, useAlertDialog } from "goi_common";
import { useOverlay } from "@toss/use-overlay";

import { KakaoLoginBottomSheet } from "./Modals/KakaoLoginBottomSheet";
import { useRouter } from "next/router";

// 로그인 여부를 확인하는 함수
const isUserLoggedIn = () => {
  const accessToken = getCookie(
    process.env.NEXT_PUBLIC_IS_LOGGED_IN_COOKIE as string
  );

  return Boolean(accessToken); // accessToken이 있으면 true, 없으면 false 반환
};

export const isLoggedInAtom = atom(
  isUserLoggedIn(), // 초기 상태는 로그인 여부에 따라 결정
  (get, set, value: boolean) => {
    set(isLoggedInAtom, value); // 로그인 상태를 업데이트
  }
);

export const AuthProvider = ({
  children,
  redirectUrl,
}: PropsWithChildren<{ redirectUrl?: string }>) => {
  const isLoggedIn = useAtomValue(isLoggedInAtom);

  const { openAlertDialog, closeAlertDialog } = useAlertDialog();
  const kakoLoginOverlay = useOverlay();

  const openKakaoLoginBottomSheet = (redirectTo: string) => {
    kakoLoginOverlay.open(({ isOpen, close: onClose }) => (
      <KakaoLoginBottomSheet
        isOpen={isOpen}
        onClose={onClose}
        redirectTo={redirectTo}
        event={{
          path: "TODO",
          trigger_and_target: "TODO",
        }}
      />
    ));
  };

  const router = useRouter();

  useEffect(() => {
    if (!isLoggedIn && router.isReady) {
      openAlertDialog({
        content: "로그인이 만료되었습니다.\n다시 로그인해주세요.",
        buttonText: "확인",
        onClickButton: () => {
          closeAlertDialog();
          openKakaoLoginBottomSheet(redirectUrl || router.asPath);
        },
      });
    }
  }, [isLoggedIn, router]);

  if (!isLoggedIn) {
    return null;
  }

  return <>{children}</>;
};
