import {
  colors,
  customEvent,
  GoiLogo,
  HStack,
  Icon,
  NextLink,
  useAlertDialog,
  VStack,
} from "goi_common";

function RenewalFooter() {
  const { openAlertDialog } = useAlertDialog();

  return (
    <VStack
      width="100%"
      background={colors.warmGray850}
      padding="60px 24px 84px"
      alignItems="center"
      className="caption1 white"
    >
      <VStack width="100%" gap="24px">
        <VStack gap="12px">
          <HStack justifyContent="space-between">
            <GoiLogo color="white" size={36} />
            <HStack alignItems="center" gap="12px">
              <NextLink
                href="https://blog.naver.com/PostList.naver?blogId=goifuneral"
                onClick={() => {
                  customEvent({
                    path: "footer",
                    trigger_and_target: "blog_icon-blog",
                  });
                }}
              >
                <Icon src="/blog.svg" width={34} height={15} alt="blog" />
              </NextLink>
              <NextLink
                href="https://www.youtube.com/c/고이TV알기쉬운장례정보"
                onClick={() => {
                  customEvent({
                    path: "footer",
                    trigger_and_target: "youtube_icon-youtube",
                  });
                }}
              >
                <Icon src="/youtube.svg" width={24} height={24} alt="blog" />
              </NextLink>
              <NextLink
                href="https://www.instagram.com/goi.funeral/"
                onClick={() => {
                  customEvent({
                    path: "footer",
                    trigger_and_target: "instagram_icon-instagram",
                  });
                }}
              >
                <Icon
                  src="/instagram.svg"
                  width={24}
                  height={24}
                  alt="instagram"
                />
              </NextLink>
            </HStack>
          </HStack>
          <div>(주)고이장례연구소</div>
          <VStack className="gray300" gap="4px">
            <div
              onClick={() =>
                openAlertDialog({
                  content:
                    "불편을 드려 죄송합니다.\n곧 업데이트 될 예정입니다.",
                  buttonText: "확인",
                })
              }
            >
              공지사항
            </div>
            <a href="https://goifuneral.notion.site/22e2ed3aa6c54e04822d9669c572c0a1">
              이용약관
            </a>
            <a
              className="orange650 font-700"
              href="https://goifuneral.notion.site/544becd151c946618d151bd9b2659414"
            >
              개인정보처리방침
            </a>
            <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=8318701971">
              사업자정보확인
            </a>
            <NextLink
              href="/my/"
              onClick={() => {
                customEvent({
                  path: "footer",
                  trigger_and_target: "mypage-uv_my",
                });
              }}
            >
              마이페이지
            </NextLink>
            <NextLink
              href="/my/"
              onClick={() => {
                customEvent({
                  path: "footer",
                  trigger_and_target: "cs_center-uv_my",
                });
              }}
            >
              고객센터
            </NextLink>
            <NextLink
              href="/consultant/search/"
              onClick={() => {
                customEvent({
                  path: "footer",
                  trigger_and_target: "footer-uv_consultant-search",
                });
              }}
            >
              상담사 조회
            </NextLink>
          </VStack>
        </VStack>

        <div className="gray500">
          <div>
            <div>대표이사 : 송슬옹 </div>
            <div> 개인정보관리책임자 : 김소현</div>
          </div>
          <div>사업자 등록번호 : 831-87-01971</div>
          <div>통신판매업신고번호 : 2021-서울관악-2417</div>
          <div>
            <div>Tel : 1666-9784</div>
            <div> 주소 : 서울 관악구 신림로 122 2층</div>
          </div>
          <div>
            <div> 이메일 : contact@goifuneral.co.kr</div>
          </div>
        </div>

        <div>
          <div>
            © {new Date().getFullYear()}. (주)고이장례연구소 ALL RIGHTS
            RESERVED.
          </div>
        </div>
      </VStack>
    </VStack>
  );
}

export default RenewalFooter;
