import { Axios } from "@/utils/axios"; // 부고 생성 함수
import {
  ObituaryResponse,
  ObituaryRequestBody,
  ObituarySanju,
  ObituarySummary,
  BankAccountResponse,
} from "@/containers/BugoContainer/type";
import { PaginationResponse } from "@/types/apis";

/** isAuthenticated */
export const createObituary = async (body: ObituaryRequestBody) => {
  const { data } = await Axios.post<string>("/obituaries/", body);
  return data;
};

/** isAuthenticated, isOwner */
export const updateObituary = async ({
  key,
  body,
}: {
  key: ObituaryResponse["key"];
  body: ObituaryRequestBody;
}) => {
  const { data } = await Axios.put<string>(`/obituaries/${key}/`, body);
  return data;
};

export const getObituaries = async (key: ObituaryResponse["key"]) => {
  const { data } = await Axios.get<ObituaryResponse>(`/obituaries/${key}/`);
  return data;
};

export const deleteObituary = async (key: ObituaryResponse["key"]) => {
  const { data } = await Axios.delete(`/obituaries/${key}/`);
  return data;
};

export const shareObituaryToSangju = async (
  key: ObituaryResponse["key"],
  body: { sangju: ObituarySanju["key"] }
) => {
  const { data } = await Axios.post(
    `/obituaries/${key}/sangju_invitation/`,
    body
  );
  return data;
};

export const getObituarySummaryList = async () => {
  const { data } = await Axios.get<PaginationResponse<ObituarySummary>>(
    "/obituaries/"
  );
  return data;
};

export type ObituarySangjuVersionResponse = {
  key: string;
  name: string;
  relation: string;
  phone: string;
  obituary: ObituaryResponse;
  bank_account: BankAccountResponse;
};

export const getObituariesSangjuVersion = async (
  authentication_key: string
) => {
  const { data } = await Axios.get<ObituarySangjuVersionResponse>(
    `/authenticated_obituary_sangjus/${authentication_key}/`
  );
  return data;
};

export type ObituaryVisitorVersionResponse = {
  key: string;
  name: string;
  relation: string;
  phone: string;
  obituary: ObituaryResponse;
  bank_accounts: BankAccountResponse[];
};

export const getObituariesVisitorVersion = async (
  key: ObituaryResponse["sangjus"][0]["key"]
) => {
  const { data } = await Axios.get<ObituaryVisitorVersionResponse>(
    `/obituary_sangjus/${key}/`
  );
  return data;
};
