import { Box, VStack } from "@chakra-ui/react";
import { BottomSheet, EventProps } from "goi_common";
import { useRouter } from "next/router";
import KakaoButton from "../KakaoButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  event: EventProps;
  redirectTo?: string;
}

export const KakaoLoginBottomSheet = ({
  isOpen,
  onClose,
  event,
  redirectTo,
}: Props) => {
  const router = useRouter();

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      <VStack
        gap="0"
        css={{
          alignContent: "start",
          padding: "30px 20px",
        }}
      >
        <Box className="subtitle_18_sb" width="100%">
          로그인이 필요한 서비스에요.
        </Box>
        <Box
          className="body_16_r"
          css={{
            width: "100%",
            margin: "8px 0 25px 0",
            whiteSpace: "break-spaces",
          }}
        >
          {"부고장과 조문객 연락처를 계속 저장해두기 위해서 로그인이 필요해요."}
        </Box>
        <KakaoButton
          onClick={() => router.push(`/login?redirectTo=${redirectTo || ""}`)}
          event={event}
        >
          카카오톡으로 3초만에 시작하기
        </KakaoButton>
      </VStack>
    </BottomSheet>
  );
};
