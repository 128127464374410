import { Box } from "@chakra-ui/react";
import { VStack } from "goi_common";

import { ReactNode } from "react";

interface HomeMenuCardProps {
  icon: ReactNode;
  title: string;
  description?: ReactNode;
  count?: ReactNode;
  onClick: () => void;
  width?: string;
  height?: string;
}

export const HomeMenuPrimaryCard = ({
  icon,
  title,
  description,
  width,
  height,
  onClick,
}: HomeMenuCardProps) => {
  return (
    <Box
      onClick={onClick}
      css={{
        borderRadius: "20px",
        background: "rgba(245, 245, 245, 0.70)",
        overflow: "hidden",
        padding: "16px",
        width,
        height,
      }}
    >
      <VStack gap="16px">
        {icon}
        <VStack gap="4px">
          <div className="subtitle_18_sb gray900">{title}</div>
          {description && (
            <div className="caption_12_r gray600">{description}</div>
          )}
        </VStack>
      </VStack>
    </Box>
  );
};

export const HomeMenuSecondaryCard = ({
  icon,
  title,
  description,
  width,
  height,
  onClick,
  count,
}: HomeMenuCardProps) => {
  return (
    <div
      css={{
        position: "relative",
      }}
    >
      <Box
        onClick={onClick}
        css={{
          borderRadius: "20px",
          overflow: "hidden",
          padding: "16px",
          background: "rgba(245, 245, 245, 0.20)",
          border: "1px solid rgba(255, 255, 255, 0.30)",
          width,
          height,
        }}
      >
        <VStack gap="12px">
          {icon}
          <VStack>
            <div className="body_16_r white">{title}</div>

            {description && (
              <div className="caption_12_r gray200">{description}</div>
            )}
          </VStack>
        </VStack>
      </Box>
      {count && <>{count}</>}
    </div>
  );
};
